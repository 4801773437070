import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ceesarCore from '../../ceesar/core';
import ceesarDesign from '../../ceesar/design';
import {CeesarBackend} from '../../ceesar/backend';
import ceesarScenarioDesign from '../../ceesar.scenario';
import {CeesarRouter} from '../../ceesar/router';

// SETUP
import routerSetup from '../../ceesar/setup/router';
import initOptions from '../../ceesar/setup/keycloak';
import backendSetup from '../../ceesar.scenario/setup/backend';

let ceesarRouter = new CeesarRouter(routerSetup);
ceesarRouter.registerService("keycloak", initOptions);

let ceesarBackend = new CeesarBackend(backendSetup);

Vue.config.productionTip = false

Vue.use(ceesarCore);
Vue.use(ceesarDesign);
Vue.use(ceesarScenarioDesign);
Vue.use(ceesarBackend);

Vue.use(ceesarRouter);

ceesarRouter.startApplication(process.env.VUE_APP_KEYCLOAK_CLIENTID_SCENARIO_RANKING, () => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app');
});