<template>
  <CEESAR-Application>

    <CEESAR-Header>
      <template slot="Left">
        <CEESAR-ApplicationLogo :imageSource="require('@/assets/images/avenue_logo.png')"/>
      </template>

      <template slot="Middle">
        <CEESAR-ApplicationTitle title="Scenario Ranking" />
      </template>

      <template slot="Right">
        <CEESAR-Auth-UserDropDown contentAlign="Right"/>
      </template>

    </CEESAR-Header>
      
    <CEESAR-Main>
      <CEESAR-View id="Ranking-View" :title="viewTitle">
        <template slot="Menu">
          <CEESAR-MenuItem v-if="currentState == 1"
              label="Quit" class="Button-EndRanking" v-on:click="switchToThanks"/>
        </template>

        <div v-if="currentState == 0">
          <CEESAR-MessageLabel>
            Hello <strong>{{currentUser}}</strong>! Welcome to the <strong>Scenario ranking</strong> tool.<br>
            <br>
            With your contribution, this tool will help ranking scenarios according to theirs criticality.<br>
            <br>
            For each pair of situation, please choose the one which seems, to you, most likely to result one day in an accident.<br>
            <br>
            Please take the necessary time for each comparison to choose, relying on your own experience, the most critical scenario.<br>
            If you really think, after proper consideration, that both situations are equally critical, you can express that with the <strong>Draw!</strong> button.<br>
            <br>

            <div v-if="sites.length>0">
              Please select your <strong>operation site</strong> then click on the <strong>Begin Scenarios Ranking</strong> button.<br>
              <br>
              <br>
            </div>
            <div v-else>
              <CEESAR-MessageLabel format="Warning">
                Sorry, you cannot start the <strong>Scenario ranking</strong> because it seems that you are not attached to an Operating Site.<br>
              </CEESAR-MessageLabel>
            </div>

          </CEESAR-MessageLabel>

          <CEESAR-Card>
            <CEESAR-ComboBox-DropDown v-if="sites.length>0"
                        label="Operation site:" :entries="sites" :horizontal="true"
                        v-on:change="OnSiteChanged" :value="selectedSiteId"
                        layout="DropDown"/>
            <CEESAR-Button v-if="sites.length>0" label="Begin Scenario Ranking" v-on:click="switchToRanking"/>
          </CEESAR-Card>
         
        </div>

        <div v-if="currentState == 1" class="Scenario-Ranking">
          <div id="ScenarioContainer" class="Scenario-Cards">

            <CEESAR-Card id="Scenario1" title="Scenario 1" class="Scenario-Card1">
              <CEESAR-ScenarioCard :scenario="scenario1"/>
              <template slot="Buttons">
                <CEESAR-Button label="This is the most critical" v-on:click="Scenario1Wins"/>
              </template>
            </CEESAR-Card>

            <CEESAR-Button label="Draw!" class="Button-Draw" v-on:click="Draw"/>

            <CEESAR-Card id="Scenario2" title="Scenario 2" class="Scenario-Card2">
              <CEESAR-ScenarioCard :scenario="scenario2"/>
              <template slot="Buttons">
                <CEESAR-Button label="This is the most critical" v-on:click="Scenario2Wins"/>
              </template>
            </CEESAR-Card>
          </div>

        </div>

        <div v-if="currentState == 2">
          <CEESAR-MessageLabel>
            <strong>Thank you for your participation!</strong><br>
            <br>
            You can come back later at any time to resume the <strong>Scenario ranking</strong> or 
            you can select an <strong>operation site</strong> and click on the <strong>Resume Scenarios Ranking</strong> button.<br>
            <br>
            <br>

          </CEESAR-MessageLabel>

          <CEESAR-Card>
            <CEESAR-ComboBox-DropDown v-if="sites.length>0"
                          label="Operation site:" :entries="sites" :horizontal="true"
                          v-on:change="OnSiteChanged" :value="selectedSiteId"/>
            <CEESAR-Button label="Resume Scenario Ranking" v-on:click="switchToRanking"/>
          </CEESAR-Card>

        </div>
      </CEESAR-View>
    </CEESAR-Main>

    <CEESAR-Footer>
      <template slot="Left">
        <CEESAR-ApplicationLogo :url="portalUrl"/>
      </template>
    </CEESAR-Footer>
  </CEESAR-Application>
</template>

<script>

import { Rating, rate_1vs1 } from 'ts-trueskill';


export default {
  name:"Application",
  data(){
    return{
      currentState: 0,

      sites: [],
      selectedSiteId: undefined,

      scenario1_ranking: undefined,
      scenario2_ranking: undefined,
      scenario1: undefined,
      scenario2: undefined
    };
  },
  computed:
  {
    viewTitle()
    {
      let titles = ["Welcome", "Ranking", "Thanks!"];
      let title = titles[this.currentState];

      if(this.currentState == 1)
      {
        title += " (" + this.currentSiteLabel + ")";
      }
      return title;
    },
    currentUser()
    {
      return this.$ceesar.router.getUserName();
    },
    currentSiteLabel(){
      let selectedSite = this.sites.find(x => x.id == this.selectedSiteId);
      if(selectedSite != undefined)
        return selectedSite.label;
      return "<>";
    },
    portalUrl(){
      return this.$ceesar.router.getApplicationUrl(process.env.VUE_APP_KEYCLOAK_CLIENTID_PORTAL);
    }
  },
  async mounted(){
    this.$ceesar.design.applyTheme(document.querySelector('html'), "public");
    await this.loadOperatorSites();
  },
  methods:
  {
    OnSiteChanged(pValue){
      this.selectedSiteId = pValue;
    },
    async loadOperatorSites(){
      let allSites = await this.$ceesar.backend.GetModelAsync("site", "get_all");
      let userSiteCodes = await this.$ceesar.router.getCustomAttribute("site_code");

      let authorizedSites = [];

      allSites.forEach(site => {
        for(let i = 0; i < userSiteCodes.length; i++)
        {
          let code = userSiteCodes[i];
          if (site.code == code)
          {
            authorizedSites.push(site);
          }
        }
      });

      this.sites = authorizedSites;
      this.selectedSiteId = this.sites[0].id;
    },

    async switchToRanking()
    {
      this.currentState = 1;
      await this.getNextMatches();
    },
    switchToThanks()
    {
      this.currentState = 2;
    },
    async getNextMatches()
    {
      // default rating
      let defaultRating = new Rating();

      let filter = {
        site_id: this.selectedSiteId,
        default_skill_rating: defaultRating.mu,
        default_skill_uncertainty: defaultRating.sigma
      };
      let res = await this.$ceesar.backend.GetModelAsync("scenario", "get_next_match", filter);

      this.scenario1_ranking = res.scenario_1;
      this.scenario2_ranking = res.scenario_2;
      this.scenario1 = await this.$ceesar.backend.GetModelByIdAsync("scenario", this.scenario1_ranking.scenario_id);
      this.scenario2 = await this.$ceesar.backend.GetModelByIdAsync("scenario", this.scenario2_ranking.scenario_id);

      this.ScenariosReset();

      window.setTimeout(() =>{
        this.ShowScenarios();
      }, 100);
    },
    ShowScenarios(pScenario1, pScenario2)
    {
      let eScenario1 = document.getElementById("Scenario1");
      eScenario1.classList.add("Scenario-Card1-In");

      let eScenario2 = document.getElementById("Scenario2");
      eScenario2.classList.add("Scenario-Card2-In");

      window.setTimeout(() => {
        this.ScenariosReset();
        eScenario1.classList.add("Scenario-Card-Shown");
        eScenario2.classList.add("Scenario-Card-Shown");
      }, 500);
    },
    ScenariosReset()
    {
      let eScenario1 = document.getElementById("Scenario1");
      eScenario1.classList.remove("Scenario-Card-Wins");
      eScenario1.classList.remove("Scenario-Card-Loses");
      eScenario1.classList.remove("Scenario-Card-Shown");

      let eScenario2 = document.getElementById("Scenario2");
      eScenario2.classList.remove("Scenario-Card-Wins");
      eScenario2.classList.remove("Scenario-Card-Loses");
      eScenario1.classList.remove("Scenario-Card-Shown");
    },

    async Scenario1Wins()
    {
      let eScenario1 = document.getElementById("Scenario1");
      eScenario1.classList.add("Scenario-Card-Wins");

      let eScenario2 = document.getElementById("Scenario2");
      eScenario2.classList.add("Scenario-Card-Loses");

      await this.EndMatch(1);

      window.setTimeout(() => {
        this.getNextMatches();
      }, 500);
    },
    async Scenario2Wins()
    {
      let eScenario1 = document.getElementById("Scenario1");
      eScenario1.classList.add("Scenario-Card-Loses");

      let eScenario2 = document.getElementById("Scenario2");
      eScenario2.classList.add("Scenario-Card-Wins");

      await this.EndMatch(2);

      window.setTimeout(() => {
        this.getNextMatches();
      }, 500);
    },
    async Draw()
    {
      let eScenario1 = document.getElementById("Scenario1");
      eScenario1.classList.add("Scenario-Card-Wins");

      let eScenario2 = document.getElementById("Scenario2");
      eScenario2.classList.add("Scenario-Card-Wins");

      await this.EndMatch(0);

      window.setTimeout(() => {
        this.getNextMatches();
      }, 500);
    },

    async EndMatch(pWinner)
    {
      let winner = this.scenario1;
      let winnerRanking = this.scenario1_ranking;
      let loser = this.scenario2;
      let loserRanking = this.scenario2_ranking;

      if(pWinner == 2)
      {
        winner = this.scenario2;
        winnerRanking = this.scenario2_ranking;
        loser = this.scenario1;
        loserRanking = this.scenario1_ranking;
      }

      let draw = 0;
      let matchResult = 1;

      if (pWinner == 0)
      {
        draw = 1;
        matchResult = 0;
      }

      let rating1 = new Rating(winnerRanking.skill_rating, winnerRanking.skill_uncertainty);
      let rating2 = new Rating(loserRanking.skill_rating, loserRanking.skill_uncertainty);

      let [newRating1, newRating2] = rate_1vs1(rating1, rating2, draw);

      let newMatch = {
        site_id: this.selectedSiteId,
        scenario_1_id: winner.id,
        scenario_2_id: loser.id,
        user_name: this.$ceesar.router.getUserFullName(),
        timestamp: new Date().toISOString().slice(0, 19).replace('T', ' '),
        result: matchResult
      };

      let winnerResult = {
        site_id: this.selectedSiteId,
        scenario_id: winner.id,
        skill_rating: newRating1.mu,
        skill_uncertainty: newRating1.sigma,
        nb_games: winnerRanking.nb_games + 1
      };

      let loserResult = {
        site_id: this.selectedSiteId,
        scenario_id: loser.id,
        skill_rating: newRating2.mu,
        skill_uncertainty: newRating2.sigma,
        nb_games: loserRanking.nb_games + 1
      };

      let res = {};
      if (winnerRanking.ranking_id > 0)
      {
        winnerResult["id"] = winnerRanking.ranking_id;
        res = await this.$ceesar.backend.PutModelAsync("scenario_ranking", winnerResult);
      }
      else
        res = await this.$ceesar.backend.PostModelAsync("scenario_ranking", winnerResult);

      if (loserRanking.ranking_id > 0)
      {
        loserResult["id"] = loserRanking.ranking_id;
        res = await this.$ceesar.backend.PutModelAsync("scenario_ranking", loserResult);
      }
      else
        res = await this.$ceesar.backend.PostModelAsync("scenario_ranking", loserResult);

      res = await this.$ceesar.backend.PostModelAsync("scenario_match", newMatch);
    }
  }
}
</script>

<style lang="less">

#Ranking-View{
  top: 80px;
  left: 15px;
  right: 15px;
}

.Scenario-Ranking{
  position: absolute;
  top : 10%;
  left: -20%;
  right: -20%;

  display: flex;
  flex-flow: column;
  align-items: center;

  .Button-Draw{
    margin-left: 10px;
    margin-right: 10px;
  }


  .Scenario-Cards{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-start;

    .Scenario-Card1, .Scenario-Card2{
      align-items: center;
    }


    .Scenario-Card1{
      opacity: 0;
      left: 0;
    }
    .Scenario-Card2{
      opacity: 0;
      right: 0;
    }

    .Scenario-Card1-In{
      animation: card-show-from-left 0.3s ease-in forwards;
    }
    .Scenario-Card2-In{
      animation: card-show-from-right 0.3s ease-in forwards;
    }

    .Scenario-Card-Shown{
      opacity: 1;
    }

    .Scenario-Card-Wins{
      animation: card-wins 0.3s ease-in forwards;
    }
    .Scenario-Card-Loses{
      animation: card-loses 0.3s ease-in forwards;
    }

  }
}


@keyframes card-show-from-left {
  0% {opacity: 0; transform: translateX(-100%);}
  100% {opacity: 1; transform: translateX(0);}
}

@keyframes card-show-from-right {
  0% {opacity: 0; transform: translateX(100%);}
  100% {opacity: 1; transform: translateX(0);}
}

@keyframes card-wins {
  0% {opacity: 1; transform: scale3d(1, 1, 1);}
  100% {opacity: 0; transform: scale3d(1.5, 1.5, 1);}
}

@keyframes card-loses {
  0% {opacity: 1; transform: translateY(0);}
  100% {opacity: 0; transform: translateY(100%);}
}




</style>
